body {
  text-align: center;
}
.navbar {
  background: radial-gradient(circle at 18.7% 37.8%, rgb(83, 83, 83) 0%, rgb(214, 214, 214) 90%);
  padding: 10px;
}
.navlink {
  margin:10px;
  background-color: rgba(255, 255, 255, 0);
  padding: 3px;
}
/*Basic a styling*/

/* unvisited link */
a:link {
  color: rgb(0, 0, 0);
  text-decoration: none;

}

/* visited link */
a:visited {
  color: rgb(0, 0, 0);
  text-decoration: none;

}

/* mouse over link */
a:hover {
  color: rgb(73, 73, 73);
  text-decoration: none;

}

/* selected link */
a:active {
  color: rgb(0, 0, 0);
  text-decoration: none;

}




/* unvisited link */
.navlink a:link {
  color: rgb(255, 255, 255);
  text-decoration: none;

}

/* visited link */
.navlink a:visited {
  color: rgb(255, 255, 255);
  text-decoration: none;

}

/* mouse over link */
.navlink a:hover {
  color: rgb(255, 255, 255);
  text-decoration: none;

}

/* selected link */
.navlink a:active {
  color: rgb(255, 255, 255);
  text-decoration: none;

}

.article-conent {
  padding: 20px;
  text-align: left;
}
.article-conent-list {
  padding-left: 20vw;
  padding-top:20px;
  padding-bottom: 20px;
  padding-right: 20vw;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.055) ;
}
